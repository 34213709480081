<template>
    <Button label="Integrar ao SAS" icon="pi pi-check-circle" @click="confirmar()" />
</template>

<script>
import LeadsService from './services';

export default {
    props: {
        lead: {
            type: Object,
        },
    },

    emits: ['atualizarLead'],

    methods: {
        confirmar() {
            this.$confirm.require({
                message: `Tem certeza que deseja integrar a lead ${this.lead.leadId} ao SAS?`,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.integrar();
                },
            });
        },

        integrar() {
            this.$store.dispatch('addRequest');
            LeadsService.integrar(this.lead.leadRegistroId).then((response) => {
                if (response?.success) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'RdStation - Lead',
                        detail: 'Lead integrada com sucesso',
                        life: 3000,
                    });
                    this.$store.dispatch('removeRequest');
                    this.$emit('atualizarLead', response.data);
                } else {
                    this.erros = response.errors;
                    this.$store.dispatch('removeRequest');
                }
            });
        },
    },
};
</script>
