<template>
    <detalhe titulo="Objeto" :size="size">
        <vue-json-pretty :data="JSON.parse(lead?.objetoIntegracaoPf)" :showDoubleQuotes="true" v-if="lead?.objetoIntegracaoPf?.isJson()"></vue-json-pretty>
        <span v-if="!lead?.objetoIntegracaoPf?.isJson()">{{ lead?.objetoIntegracaoPf }}</span>
    </detalhe>
    <detalhe titulo="Retorno" :size="size">
        <vue-json-pretty :data="JSON.parse(lead?.retornoIntegracaoPf)" :showDoubleQuotes="true" v-if="lead?.retornoIntegracaoPf?.isJson()"></vue-json-pretty>
        <span v-if="!lead?.retornoIntegracaoPf?.isJson()">{{ lead?.retornoIntegracaoPf }}</span>
    </detalhe>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

export default {
    props: {
        lead: {
            type: Object,
        },
    },

    data() {
        return {
            size: '100',
        };
    },

    components: {
        VueJsonPretty,
    },
};
</script>