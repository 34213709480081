import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_RDSTATION_PORT}${process.env.VUE_APP_API_PATH}/rdstation`;
const urlBase = `${api}/leads`;

export default {
    obterTodos(filtro) {
        return axiosJwt.get(`${urlBase}?dataInteracaoDe=${filtro.dataInteracaoDe
            }&dataInteracaoAte=${filtro.dataInteracaoAte
            }&statuscpfsas=${filtro.statusCpfSas
            }&statuscnpjsas=${filtro.statusCnpjSas}`
        );
    },

    obterPorId(id) {
        return axiosJwt.get(`${urlBase}/${id}`);
    },

    integrar(id) {
        return axiosJwt.post(`${urlBase}/${id}/integrar`);
    },
};
