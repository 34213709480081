<template>
    <detalhe titulo="Objeto" :size="size">
        <vue-json-pretty :data="JSON.parse(lead?.integracaoObjeto)" :showDoubleQuotes="true" v-if="lead?.integracaoObjeto?.isJson()"></vue-json-pretty>
        <span v-if="!lead?.integracaoObjeto?.isJson()">{{ lead?.integracaoObjeto }}</span>
    </detalhe>
    <detalhe titulo="Retorno" :size="size">
        <vue-json-pretty :data="JSON.parse(lead?.integracaoRetorno)" :showDoubleQuotes="true" v-if="lead?.integracaoRetorno?.isJson()"></vue-json-pretty>
        <span v-if="!lead?.integracaoRetorno?.isJson()">{{ lead?.integracaoRetorno }}</span>
    </detalhe>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

export default {
    props: {
        lead: {
            type: Object,
        },
    },

    data() {
        return {
            size: '100',
        };
    },

    components: {
        VueJsonPretty,
    },
};
</script>