<template>
    <detalhe titulo="Status" :size="size">
        <status :status="lead?.statusDescr"></status>
    </detalhe>
    <detalhe titulo="Recebido em" :size="size">
        {{ $dateFormat(lead?.dataCadastro, 'DD/MM/YYYY HH:mm:ss') }}
    </detalhe>
    <detalhe titulo="Integrado em" :size="size" v-if="lead?.dataIntegracao">
        {{ $dateFormat(lead?.dataIntegracao, 'DD/MM/YYYY HH:mm:ss') }}
    </detalhe>
    <detalhe titulo="Lead Id" :size="size">
        {{ lead?.leadId }}
    </detalhe>
    <detalhe titulo="Descrição do Atendimento" :size="size">
        {{ lead?.descricaoAtendimento }}
    </detalhe>
    <detalhe titulo="Data Interação" :size="size">
        {{ $dateFormat(lead?.dataInteracao, 'DD/MM/YYYY HH:mm:ss') }}
    </detalhe>
    <detalhe titulo="CPF" :size="size">
        {{ lead?.cpf }} <status :status="lead?.cpfSasOk ? 'SAS OK' : 'SAS NOK'" v-if="mostrarStatusSas(lead?.cpf, lead?.status)"></status>
        <status status="CPF NÃO RECEBIDO" v-if="!lead?.cpf"></status>
    </detalhe>
    <detalhe titulo="CNPJ" :size="size">
        {{ lead?.cnpj }} <status :status="lead?.cnpjSasOk ? 'SAS OK' : 'SAS NOK'" v-if="mostrarStatusSas(lead?.cnpj, lead?.status)"></status>
        <status status="CNPJ NÃO RECEBIDO" v-if="!lead?.cnpj"></status>
    </detalhe>
    <detalhe titulo="Nome" :size="size">
        {{ lead?.nome }}
    </detalhe>
    <detalhe titulo="Cidade" :size="size">
        {{ lead?.cidade }}
    </detalhe>
    <detalhe titulo="UF" :size="size">
        {{ lead?.estado }}
    </detalhe>
    <detalhe titulo="Erro de integração" :size="size" v-if="lead?.erroIntegracao">
        {{ lead?.erroIntegracao }}
    </detalhe>
    <detalhe titulo="Atualizado em" :size="size">
        {{ $dateFormat(lead?.dataAtualizacao, 'DD/MM/YYYY HH:mm:ss') }}
    </detalhe>
</template>

<script>
import { leadStatus } from './LeadStatus';

export default {
    props: {
        lead: {
            type: Object,
        },
    },

    data() {
        return {
            size: '180',
        };
    },

    methods: {
        mostrarStatusSas(dado, status) {
            return dado && status != leadStatus.RECEBIDO;
        },
    },
};
</script>
