<template>
    <detalhe titulo="Objeto" :size="size">
        <vue-json-pretty
            :data="JSON.parse(lead?.objetoIntegracaoVinculoPfPj)"
            :showDoubleQuotes="true"
            v-if="lead?.objetoIntegracaoVinculoPfPj?.isJson()"
        ></vue-json-pretty>
        <span v-if="!lead?.objetoIntegracaoVinculoPfPj?.isJson()">{{ lead?.objetoIntegracaoVinculoPfPj }}</span>
    </detalhe>
    <detalhe titulo="Retorno" :size="size">
        <vue-json-pretty
            :data="JSON.parse(lead?.retornoIntegracaoVinculoPfPj)"
            :showDoubleQuotes="true"
            v-if="lead?.retornoIntegracaoVinculoPfPj?.isJson()"
        ></vue-json-pretty>
        <span v-if="!lead?.retornoIntegracaoVinculoPfPj?.isJson()">{{ lead?.retornoIntegracaoVinculoPfPj }}</span>
    </detalhe>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

export default {
    props: {
        lead: {
            type: Object,
        },
    },

    data() {
        return {
            size: '100',
        };
    },

    components: {
        VueJsonPretty,
    },
};
</script>