<template>
    <painel :titulo="`RDStation - Lead - ${lead?.leadId}`" icone="pi pi-comments" :refreshFunction="obterLead">
        <div class="mb-4">
            <TabView v-model:activeIndex="activeIndex">
                <TabPanel header="Dados Gerais">
                    <dados-gerais :lead="lead"></dados-gerais>
                </TabPanel>
                <TabPanel header="Lead Recebido">
                    <vue-json-pretty v-if="lead" :data="JSON.parse(lead?.dadosRecebidos)" :showDoubleQuotes="true"></vue-json-pretty>
                </TabPanel>
                <TabPanel header="Integração Atendimento" v-if="lead">
                    <integracao-atendimento :lead="lead"></integracao-atendimento>
                </TabPanel>
                <TabPanel header="Integração PF" v-if="lead">
                    <integracao-pf :lead="lead"></integracao-pf>
                </TabPanel>
                <TabPanel header="Integração PJ" v-if="lead">
                    <integracao-pj :lead="lead"></integracao-pj>
                </TabPanel>
                <TabPanel header="Integração Vínculo" v-if="lead">
                    <integracao-vinculo :lead="lead"></integracao-vinculo>
                </TabPanel>
            </TabView>
        </div>
        <btn-integrar v-if="mostrarBtnIntegrar" :lead="lead" @atualizarLead="atualizarLead($event)"></btn-integrar>
        <btn-voltar :route="{ name: 'Leads' }"></btn-voltar>
    </painel>
</template>

<script>
import LeadsService from './services';
import BtnIntegrar from './BtnIntegrar';
import DadosGerais from './DadosGerais';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import IntegracaoAtendimento from './IntegracaoAtendimento';
import IntegracaoPf from './IntegracaoPf';
import IntegracaoPj from './IntegracaoPj';
import IntegracaoVinculo from './IntegracaoVinculo';

export default {
    components: {
        BtnIntegrar,
        DadosGerais,
        VueJsonPretty,
        IntegracaoAtendimento,
        IntegracaoPf,
        IntegracaoPj,
        IntegracaoVinculo,
    },

    data() {
        return {
            lead: null,
            activeIndex: 0,
        };
    },

    methods: {
        obterLead() {
            this.$store.dispatch('addRequest');
            LeadsService.obterPorId(this.$route.params.id).then((response) => {
                if (response?.success) {
                    this.lead = response.data;
                } else {
                    this.lead = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        atualizarLead(leadAtualizada) {
            this.lead = leadAtualizada;
        },
    },

    computed: {
        mostrarBtnIntegrar() {
            return this.activeIndex == 0 && this.$temAcessoView('RD-LEADS-01');
        },
    },

    mounted() {
        this.obterLead();
        if (this.$route.query.view == 'dados') {
            this.activeIndex = 1;
        }
    },
};
</script>
